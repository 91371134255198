import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `https://rickandmortyapi.com/graphql`,
  
  fetch,
  onError: error => {
   
  },
});
